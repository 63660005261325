import axios from "axios"; //引入
// import { getToken } from "@/utils/token";
import { Message } from "element-ui"; // 导入自己要用的ui组件库

let config = {
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 30000, //设置最大请求时间
};
const service = axios.create(config);

/* 请求拦截器（请求之前的操作） */
service.interceptors.request.use(
    (config) => {
        //如果有需要在这里开启请求时的loading动画效果
        // config.headers.Authorization = getToken; //添加token,需要结合自己的实际情况添加，
        return config;
    },
    (err) => Promise.reject(err)
);

/* 请求之后的操作 */
service.interceptors.response.use(
    (res) => {
        //关闭请求时的loading动
        //返回401无权限，可能会是跳回到登录页的操作，结合项目写
        if (res.data.code === 401) {
            Message.error("无权限操作");
        }else if(res.data.code === 400) {
            Message.error("请求网络失败");
        }else if (res.data.code === 404) {
            Message.error("请求网络失败");
        }else if(res.data.code === 500){
            Message.error(res.data.msg);
        }else{
            return res.data;
        }
        
    },
    (err) => {
        if (err) {
            //在这里关闭请求时的loading动画效果
            Message.error("请求网络失败");
        }
        return Promise.reject(err);
    }
);

export default service
