<template>
    <div class="footer">
        <div class="image">
            <svg
                class="waves"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shape-rendering="auto"
            >
                <defs>
                    <path
                        id="gentle-wave"
                        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    ></path>
                </defs>
                <g class="parallax">
                    <use
                        xlink:href="#gentle-wave"
                        x="48"
                        y="0"
                        fill="rgba(25, 54 ,129, .1)"
                    ></use>
                    <use
                        xlink:href="#gentle-wave"
                        x="48"
                        y="3"
                        fill="rgba(25, 54 ,129, .5)"
                    ></use>
                    <use
                        xlink:href="#gentle-wave"
                        x="48"
                        y="5"
                        fill="rgba(25, 54 ,129, .5)"
                    ></use>
                    <use
                        xlink:href="#gentle-wave"
                        x="48"
                        y="7"
                        fill="rgba(25, 54 ,129, 1)"
                    ></use>
                </g>
            </svg>
        </div>
        <div class="con">
            <div class="content">
                <div class="box">
                    <div class="l">
                        <div class="name">
                            <div class="title" @click="goPage('/')">首页</div>
                        </div>
                        <div class="name">
                            <div class="title" @click="goPage('/about')">
                                关于我们▼
                            </div>
                            <div class="child" @click="goPage('/about', 0)">
                                企业简介
                            </div>
                            <div class="child" @click="goPage('/about', 1)">
                                企业文化
                            </div>
                            <div class="child" @click="goPage('/about', 2)">
                                企业荣誉
                            </div>
                        </div>
                        <div class="name">
                            <div class="title" @click="goPage('/innovative')">
                                创新研发▼
                            </div>
                            <div
                                class="child"
                                @click="goPage('/innovative', 0)"
                            >
                                研究方向
                            </div>
                            <div
                                class="child"
                                @click="goPage('/innovative', 1)"
                            >
                                研发基地
                            </div>
                        </div>
                        <div class="name">
                            <div
                                class="title"
                                @click="goPage('/businessDirection')"
                            >
                                业务方向▼
                            </div>
                            <div
                                class="child"
                                @click="goPage('/businessDirection', 0)"
                            >
                                医疗器械创新研发服务
                            </div>
                            <div
                                class="child"
                                @click="goPage('/businessDirection', 1)"
                            >
                                医疗智能
                            </div>
                            <div
                                class="child"
                                @click="goPage('/businessDirection', 2)"
                            >
                                科研成果转化与孵化
                            </div>
                            <div
                                class="child"
                                @click="goPage('/businessDirection', 3)"
                            >
                                其他
                            </div>
                        </div>
                        <div class="name">
                            <div
                                class="title"
                                @click="goPage('/scientificResearchResults')"
                            >
                                科研成果▼
                            </div>
                            <div
                                class="child"
                                @click="goPage('/scientificResearchResults', 0)"
                            >
                                专利
                            </div>
                            <div
                                class="child"
                                @click="goPage('/scientificResearchResults', 1)"
                            >
                                软著
                            </div>
                        </div>
                        <div class="name">
                            <div class="title" @click="goPage('/news')">
                                新闻资讯▼
                            </div>
                            <div class="child" @click="goPage('/news', 0)">
                                企业动态
                            </div>
                            <div class="child" @click="goPage('/news', 1)">
                                行业动态
                            </div>
                            <div class="child" @click="goPage('/news', 2)">
                                员工风采
                            </div>
                        </div>
                        <div class="name">
                            <div class="title" @click="goPage('/recruitment')">
                                人力资源
                            </div>
                        </div>
                        <div class="name">
                            <div class="title" @click="goPage('/contactus')">
                                联系我们▼
                            </div>
                            <div class="child" @click="goPage('/contactus', 0)">
                                联系方式
                            </div>
                            <div class="child" @click="goPage('/contactus', 1)">
                                在线联系
                            </div>
                        </div>
                    </div>
                    <div class="r">
                        <div class="r_title">联系我们</div>
                        <div class="r_1">电话：025-57718757</div>
                        <div class="r_1">地址：南京市雨花台区云密城E栋五层</div>
                        <div class="r_1">邮箱：bd@01prime.com</div>
                    </div>
                </div>
                <div class="bottom">
                    <a style="color: #fff;text-decoration: none;" href="https://beian.miit.gov.cn" target="_block">
                        Copyright ©
                        {{ new Date().getFullYear() }}
                        零壹人工智能科技研究院（南京）有限公司.
                        备案号：苏ICP备2021033686号-1</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Layout",
    methods: {
        goPage(path, act) {
            console.log(path, act);
            this.$router
                .push({
                    path: path,
                    query: {
                        act: act,
                    },
                })
                .then(() => {
                    window.location.reload();
                });

            window.scrollTo({
                top: 0,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    height: 24.4792vw;
    .con {
        height: 17.3229vw;
        background-color: #193780;
        padding-top: 5px;
        .content {
            width: 72.5vw;
            height: 100%;
            margin: 0 auto;
            position: relative;
            .box {
                display: flex;
                .l {
                    width: 44.7917vw;
                    display: flex;
                    justify-content: space-around;
                    .name {
                        width: 5.5989vw;
                        .title {
                            cursor: pointer;
                            font-size: 0.8333vw;
                            color: rgba(255, 255, 255, 0.8);
                            &:hover {
                                color: rgba(255, 255, 255, 1);
                            }
                        }
                        .child {
                            cursor: pointer;
                            width: 90%;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            // cursor: pointer;
                            margin-top: 0.5208vw;
                            font-size: 0.6771vw;
                            color: rgba(255, 255, 255, 0.4);
                            // &:hover {
                            //     color: rgba(255, 255, 255, 0.8);
                            // }
                        }
                    }
                }
                .r {
                    position: relative;
                    top: -1.0417vw;
                    margin-left: 10.4167vw;
                    color: #fff;
                    .r_title {
                        font-size: 0.9375vw;
                        font-weight: bold;
                        margin-bottom: 1.4583vw;
                    }
                    .r_1 {
                        font-size: 0.7292vw;
                        margin-bottom: 0.3125vw;
                    }
                }
            }
            .bottom {
                width: 100%;
                height: 3.9063vw;
                border-top: 0.0521vw solid rgb(136, 137, 141);
                position: absolute;
                bottom: 0;
                line-height: 3.9063vw;
                text-align: center;
                font-size: 14px;
                color: #ffffff;
            }
        }
    }
    .image {
        svg:not(:root) {
            overflow: hidden;
        }
        .waves {
            position: relative;
            width: 100%;
            height: 7.1563vw;
            margin-bottom: -7px;
            background: #f8f8f8;
        }
        .parallax > use:nth-child(1) {
            animation-delay: -2s;
            animation-duration: 7s;
        }
        .parallax > use:nth-child(2) {
            animation-delay: -3s;
            animation-duration: 10s;
        }
        .parallax > use:nth-child(3) {
            animation-delay: -4s;
            animation-duration: 13s;
        }
        .parallax > use:nth-child(4) {
            animation-delay: -5s;
            animation-duration: 20s;
        }
        .parallax > use {
            animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5)
                infinite;
        }

        @keyframes move-forever {
            0% {
                transform: translate3d(-90px, 0, 0);
            }
            100% {
                transform: translate3d(85px, 0, 0);
            }
        }
    }
}
</style>
