import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {Pagination,Row,Col,Input,RadioGroup,Radio,Button,Message} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false;
Vue.config.devtools = false;
Vue.use(Pagination);
Vue.use(Row);
Vue.use(Col);
Vue.use(Input);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Button);
Vue.prototype.$message = Message;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
