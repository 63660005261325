<template>
    <div class="box">
        <!-- 第一部分 -->
        <div class="top">
            <!-- <div class="block">
                <el-carousel height="39.0625vw" @change="change()">
                    <el-carousel-item>
                        <div class="photo1">
                            <img src="@/assets/image/home_slices/81130a31-1e6f-4a3f-8d1b-edcd0241b38b.png"
                                style="width:100%;">
                            <div class="word1">汇聚技术、产业、资本</div>
                            <div class="after"></div>
                            <div class="word2">打造基于计算机辅助设计、三维智能、人工智能的科学技术转化、产业孵化与实践应用平台。</div>
                            <button class="btn">了解详情</button>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="photo1">
                            <img src="@/assets/image/home_slices/81130a31-1e6f-4a3f-8d1b-edcd0241b38b.png"
                                style="width:100%;">
                            <div class="word1">汇聚技术、产业、资本</div>
                            <div class="after"></div>
                            <div class="word2">打造基于计算机辅助设计、三维智能、人工智能的科学技术转化、产业孵化与实践应用平台。</div>
                            <button class="btn">了解详情</button>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="photo1">
                            <img src="@/assets/image/home_slices/81130a31-1e6f-4a3f-8d1b-edcd0241b38b.png"
                                style="width:100%;">
                            <div class="word1">汇聚技术、产业、资本</div>
                            <div class="after"></div>
                            <div class="word2">打造基于计算机辅助设计、三维智能、人工智能的科学技术转化、产业孵化与实践应用平台。</div>
                            <button class="btn">了解详情</button>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div> -->
            <video class="vid" :src="url" loop muted autoplay></video>
        </div>
        <!-- 第二部分 -->
        <div class="banner">
            <div class="font1">企业简介</div>
            <div class="font2">ENTERSPIRS INTRONUCTION</div>
            <div class="font3"></div>
            <div class="font4 datav">
                <p class="number">200<span>+</span></p>
                <div class="txt">项目经验：200+</div>
            </div>
            <div class="font5 datav">
                <p class="number">300<span>+</span></p>
                <div class="txt">合作医院：300+</div>
            </div>
            <div class="font6 datav">
                <p class="number">500000<span>+</span></p>
                <div class="txt">协同医师网络500000+</div>
            </div>
            <div class="title">企业概况</div>
            <div class="context" v-html="introduction">
            </div>
            <button class="banner-btn" @click="goPage('/about')">
                了解更多
            </button>
        </div>

        <!-- 第三部分  研发创新-->
        <div class="develop">
            <p class="bigtitle">创新研发</p>
            <p class="smalltitle">INNOVATIVE RESEARCH & DEVELOPMENT</p>
            <div class="otl"></div>
            <div class="lchart">
                <div class="swiper-container lchart-ul">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item, index) in loginImage" :key="index" :style="{
                            backgroundImage: 'url(' + item.back + ')',
                        }">
                            <div class="item-context">
                                <img :src='"@/assets/image/home_slices/icon" + item.icon + ".png"'>
                                <p>{{ item.title }}</p>
                                <div class="protext">{{ item.text }}</div>
                                <div class="more">了解更多+</div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="left chartbtn swiper-button-prev">
                        <img class="beforefocu" src="@/assets/image/home_slices/zu 134.png" /><img class="img2 afterfocu" tra
                            src="@/assets/image/home_slices/zu 133.png" />
                    </div>
                    <div class="right chartbtn swiper-button-next">
                        <img class="img2 beforefocu" src="@/assets/image/home_slices/zu 134.png" /><img class="afterfocu"
                            src="@/assets/image/home_slices/zu 133.png" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 业务范围 -->
        <div class="business-scope">
            <p class="bigtitle">业务范围</p>
            <p class="smalltitle">BUSINESS SCOPE</p>
            <div class="otl"></div>
            <div class="bus">
                <div class="bc"></div>
                <div class="one">
                    <div class="one_box">
                        <img class="box_img" src="@/assets/image/home_slices/4.png" alt="" />
                    </div>
                    <div class="left">
                        <div class="line1">
                            <div class="dot"></div>
                            <div class="title">医疗器械创新研发服务</div>
                        </div>
                        <div class="line2">
                            公司为医疗研发企业提供质量可靠、系统规范的临床监查服务，保障临床试验过程、数据和文件的台规性、科学性、真实性和完整性，为产品的注册申报提供
                        </div>
                        <div class="line3">
                            <div class="circle"><i class="el-icon-caret-right"></i></div>
                            <span @click="learn(0)">了解更多详情</span>
                        </div>
                    </div>
                </div>
                <div class="two">
                    <div class="right">
                        <div class="line1">
                            <div class="dot"></div>
                            <div class="title">数字医疗与慢病管理</div>
                        </div>
                        <div class="line2">
                            <p>诚鼎医疗是零壹研究院引进和孵化企业,已经搭建了完善的慢病患者“共同照护”系统、流程和团队,经九安医疗投资支持,目前已经成功在全国200+公立医院及50+社区医院开展照护门诊,获得国内知名专家团队的支持和认可,管理全国近30万慢病患者。</p>
                            <p>共同照护是以患者为中心, 搭建医生、护士、营养师、运动师、照护师等多角色的照护团队,并借助AI智能、互联网、物联网等技术, 实现对慢病患者院内院外一体化、线下线上一体化和软硬件服务一体化的全病程规范化管理闭环，实现以患者为中心的服务供给。共同照护模式已经被写入《中国2型糖尿病防治指南(2020年版)》,被称为高度有效的患者管理模式,可以大大提升医院慢病患者管理的效率和质量,为慢病患者学习疾病知识、有效管理自身疾病提供平台指导,满足患者对医疗资源的体验和需求。</p>
                        </div>
                        <div class="line3">
                            <div class="circle"><i class="el-icon-caret-right"></i></div>
                            <span @click="learn(1)">了解更多详情</span>
                        </div>
                    </div>
                    <div class="two_box">
                        <img src="@/assets/image/home_slices/5.png" alt="">
                    </div>
                </div>

            </div>
        </div>
        <!-- 新闻中心 -->
        <div class="news">
            <p class="bigtitle">新闻中心</p>
            <p class="smalltitle">NEWS CENTER</p>
            <div class="otl"></div>
            <div class="news-main">
                <div class="news_con">
                    <div class="item" v-for="i in news" :key="i.news_id">
                        <div class="imgBc">
                            <img :src="i.list_image_url" alt="" />
                        </div>
                        <div class="title">{{ i.title_name }}</div>
                        <div class="desc">{{ i.news_summary }}</div>
                        <div class="btn" @click="jump(i.news_id)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import Top from "@/components/Top.vue";
import { get_web_base_info_list } from "@/api/apiGroup/index";
import { get_web_news_info_list } from "@/api/apiGroup/news";
export default {
    components: {
        Swiper,
        Top,
    },
    data() {
        return {
            num: 1,
            startX: 0,
            endX: 0,
            loginImage: [
                {
                    title: "数据智能",
                    icon: 1,
                    back: require("@/assets/image/home_slices/chuangxin1.jpg"),
                    text: "通过大规模机器学习和深度学习等技术，对海量数据进行处理、分析和挖掘，提取数据中所包含的有价值的信息和知识，使数据具有“智能”。",
                },
                {
                    title: "机械人+医疗",
                    icon: 3,
                    back: require("@/assets/image/home_slices/chuangxin2.jpg"),
                    text: "随着传感器，控制和传动技术的进步发展，使得机器人产品的功能越来越强大，其应用场景得到了较大的扩展。医疗领域是机器人应用的一个重要方向。",
                },
                {
                    title: "三维智能",
                    icon: 2,
                    back: require("@/assets/image/home_slices/chuangxin3.jpg"),
                    text: "三维数字智能化是未来各行业智能化应用的空间智能基础，也孕育了无数机遇和挑战。3D打印技术在医疗领域的应用，给医疗产业的发展带来无限可能。",
                },
                {
                    title: "AI+新药研发",
                    icon: 6,
                    back: require("@/assets/image/home_slices/chuangxin4.png"),
                    text: "Al制药通过机器学习 (machine learning，ML) 、深度学习 (deeplearning，DL) 等方式赋能药物靶点发现、化合物筛选等环节，同时，基于类器官图像，利用AI技术对类器官进行特征分析、定量分析以及细胞水平的分析，结合类器官相关的各类信息与患者的临床信息整合，可以更好的进行大数据的分析和挖掘。AI制药与类器官高通量筛选技术相结合，大大提升了新药研发的效率，为降本增效提供了可能。",
                },
                {
                    title: "类器官芯片与精准医疗",
                    icon: 4,
                    back: require("@/assets/image/home_slices/chuangxin5.png"),
                    text: "类器官是体外培育而成、具备三维结构的微器官，类似人体真实器官的结构，并能模拟来源组织器官的生理功能，因而可以利用类器官技术对人体的生物标本进行体外细胞培养，为患者制定专业化治疗方案。一方面类器官本身可以反映临床疾病状况的异质性，其可以代替细胞系完成中高通量的药物筛选以及药物作用机制的研究，类器官体外药敏筛选技术可以通过体外对类器官进行药物筛选和基因型分析，制定适合不同个体的治疗药物和方法，延长患者生存周期，提高患者生存质量。",
                },
                {
                    title: "物联网+医疗",
                    icon: 5,
                    back: require("@/assets/image/home_slices/chuangxin6.jpg"),
                    text: "物联网技术在医疗领域中的应用遍及该领域的各个环节，涉及从医疗信息化、身份识别、医院急救、远程监护和家庭护理、药品与耗材领域、以及医疗设备和医疗垃圾的监控、血液管理、传染控制等多个方面。",
                },
            ],
            news: [],
            url: '',
            introduction: ''

        };
    },
    created() {
        this.get_web_base_info_list(100);
        this.get_web_base_info_list(103);

        this.get_web_news_info_list();
    },

    methods: {
        goPage(path) {
            this.$router.push(path);
        },

        change() {
            console.log();
        },
        get_web_news_info_list() {
            let obj = {
                news_type: 1,
                page_index: 1,
                page_size: 3,
                web_type: 2,
            };
            get_web_news_info_list(obj).then((res) => {
                this.news = res.result_info.records;
            });
        },
        get_web_base_info_list(val) {
            let obj = {
                function_type: val,
                page_index: 1,
                page_size: 10,
                web_type: 2,
            };
            get_web_base_info_list(obj).then((res) => {
                console.log(res, 'res', obj);
                if (obj.function_type == 100) {
                    this.url = res.result_info.records[0].file_url
                } else {
                    this.introduction = res.result_info.records[1].base_desc
                }



            });
        },
        learn(val) {
            this.$router.push({
                path: 'businessDirection',
                query: { act: val }
            })
            window.scrollTo(0, 0)
        },
        jump(val) {
            console.log("news-id", val)
            this.$router.push({
                path: 'detail',
                query: { news_id: val }
            })
            window.scrollTo(0, 80)
        }
    },
    mounted() {
        new Swiper(".swiper-container", {
            loop: true, // 循环模式选项
            loopedSlides: 5,
            loopPreventsSlide: false,
            slidesPerView: 1,
            speed: 310,
            autoplay: {
                delay: 3000,
            },
            // 如果需要前进后退按钮
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
        });
    },
};
</script>


<style lang="scss" scoped>
.box {
    width: 100vw;
}

.swiper-button-next:after {
    content: "";
}

.swiper-button-prev:after {
    content: "";
}

.top {
    position: relative;
    width: 100%;

    .vid {
        width: 100%;
    }

    .word1 {
        position: absolute;
        top: 16.7188vw;
        left: 12.1354vw;
        width: 37.3438vw;
        height: 4.3229vw;
        font-size: 3.2813vw;
        font-family: MicrosoftYaHei;
        line-height: 4.5833vw;
        color: #ffffff;
        opacity: 1;
    }

    .word2 {
        position: absolute;
        left: 13.2292vw;
        top: 22.2917vw;
        width: 29.6875vw;
        height: 3.6458vw;
        font-size: 0.9375vw;
        font-family: MicrosoftYaHei;
        line-height: 1.6146vw;
        color: #ffffff;
        // letter-spacing: .5208vw;
        opacity: 1;
    }

    .after {
        width: 0px;
        height: 3.125vw;
        border: 0.5px solid #ffffff;
        opacity: 1;
        position: absolute;
        top: 22.1875vw;
        left: 12.1875vw;
    }

    .btn {
        width: 6.1458vw;
        height: 2.1875vw;
        border: 2px solid #ffffff;
        opacity: 1;
        border-radius: 44px;
        font-size: 0.7292vw;
        font-family: MicrosoftYaHei;
        line-height: 1.0417vw;
        color: #ffffff;
        background-color: transparent;
        position: absolute;
        top: 28.0729vw;
        left: 12.2917vw;
    }
}

.banner {
    position: relative;
    width: 100vw;
    height: 29.6875vw;
    background-image: url("@/assets/image/home_slices/qiyeback.jpg");

    .font1 {
        width: 6.5104vw;
        height: 1.9792vw;
        font-size: 1.5625vw;
        font-family: MicrosoftYaHei;
        line-height: 2.1875vw;
        color: #3d3d3d;
        position: absolute;
        left: 18.4896vw;
        top: 3.3333vw;
    }

    .font2 {
        width: 7.3958vw;
        height: 2.7604vw;
        font-size: 0.7292vw;
        font-family: MicrosoftYaHei;
        line-height: 1.1458vw;
        color: #193780;
        opacity: 1;
        position: absolute;
        left: 18.4896vw;
        top: 5.9896vw;
    }

    .font3 {
        width: 1.0417vw;
        height: 1.0417vw;
        background: #193780;
        opacity: 1;
        position: absolute;
        left: 18.4896vw;
        top: 8.75vw;
    }

    .datav {
        height: 4.0625vw;
        border-left: 1px solid #aaaaaa;
        padding-left: 0.4688vw;
        opacity: 1;

        .number {
            width: 4.375vw;
            height: 3.0208vw;
            font-size: 2.2396vw;
            font-family: MicrosoftYaHei;
            line-height: 3.125vw;
            color: #193780;
            opacity: 1;

            span {
                width: 0.7292vw;
                height: 0.9375vw;
                font-size: 0.7292vw;
                font-family: MicrosoftYaHei;
                line-height: 1.0417vw;
                color: #767676;
                opacity: 1;
            }
        }

        .txt {
            width: 6.4063vw;
            height: 0.9375vw;
            font-size: 0.7292vw;
            font-family: MicrosoftYaHei;
            line-height: 1.0417vw;
            color: #767676;
            opacity: 1;
        }
    }

    .font4 {
        position: absolute;
        left: 38.0729vw;
        top: 3.2813vw;
    }

    .font5 {
        position: absolute;
        left: 63.3125vw;
        top: 3.2813vw;
    }

    .font6 {
        position: absolute;
        left: 81.25vw;
        top: 3.2813vw;
    }

    .title {
        position: absolute;
        top: 10.6771vw;
        left: 38.5938vw;
        width: 6.9792vw;
        height: 2.1875vw;
        font-size: 1.4583vw;
        font-family: MicrosoftYaHei;
        line-height: 2.0313vw;
        color: #3d3d3d;
        opacity: 1;
    }

    :deep(.context) {
        width: 35.4583vw;
        height: 6.875vw;
        font-size: 0.7292vw;
        font-family: MicrosoftYaHei;
        line-height: 1.4063vw;
        color: #3d3d3d;
        opacity: 1;
        position: absolute;
        left: 38.5938vw;
        top: 14.2188vw;
        p{
            span{
                font-size: .7292vw!important;
            }
        }
    }

    .banner-btn {
        width: 8.8542vw;
        height: 2.1875vw;
        border: 2px solid #aaaaaa;
        opacity: 1;
        position: absolute;
        left: 38.5938vw;
        top: 24.7917vw;
        font-size: 0.7292vw;
        font-family: MicrosoftYaHei;
        line-height: 1.0417vw;
        color: #193780;
        cursor: pointer;
    }
}

.develop {
    .bigtitle {
        // width: 6.875vw;
        height: 2.8646vw;
        font-size: 1.6667vw;
        font-family: MicrosoftYaHei;
        line-height: 1.3542vw;
        color: #3d3d3d;
        opacity: 1;
        text-align: center;
        margin-top: 2.5vw;
    }

    .smalltitle {
        text-align: center;
        // width: 21.5625vw;
        height: 1.3542vw;
        font-size: 1.0417vw;
        font-family: MicrosoftYaHei;
        line-height: 1.3542vw;
        color: #d8d8d8;
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.35);
        opacity: 1;
    }

    .otl {
        margin-top: 0.4688vw;
        margin-left: 49.1667vw;
        width: 1.7708vw;
        height: 0.1042vw;
        background: rgba(118, 118, 118, 0.39);
        opacity: 1;
    }

    .lchart {
        position: relative;
        width: 72.9167vw;
        height: 32.2396vw;
        background: rgba(0, 0, 0, 0);
        opacity: 1;
        border: 1px solid black;
        margin-top: 1.0417vw;
        margin-left: 13.5938vw;
        overflow: hidden;

        .lchart-ul {
            height: 32.2396vw;
            width: 72.9167vw;
            list-style: none;

            .swiper-slide {
                background-repeat: no-repeat;
                background-size: 100% 100%;
                height: 32.2396vw;

                .item-context {
                    position: relative;
                    width: 29.1667vw;
                    height: 32.2917vw;
                    background: linear-gradient(270deg,
                            rgba(52, 71, 149, 0.75) 0%,
                            rgba(52, 71, 149, 0.9) 100%);

                    img {
                        width: 2.1875vw;
                        height: 3vw;
                        margin-top: 6.1458vw;
                        margin-left: 13.5417vw;
                    }

                    p {
                        width: 29.1667vw;
                        text-align: center;
                        height: 2.4479vw;
                        font-size: 1.5625vw;
                        font-family: MicrosoftYaHei-Bold;
                        line-height: 1.1458vw;
                        color: #ffffff;
                        opacity: 1;
                        margin-top: 1vw;
                    }

                    .protext {
                        width: 20.2083vw;
                        // height: 5.3646vw;
                        font-size: 0.8333vw;
                        font-family: MicrosoftYaHei;
                        line-height: 1.6667vw;
                        color: #ffffff;
                        opacity: 1;
                        margin-top: 0.7813vw;
                        margin-left: 5.4688vw;
                    }

                    .more {
                        width: 29.1667vw;
                        text-align: center;
                        height: 1.3021vw;
                        font-size: 0.8333vw;
                        font-family: MicrosoftYaHei;
                        line-height: 1.1458vw;
                        color: #ffffff;
                        opacity: 1;
                        margin-top: 1vw;
                    }
                }
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .left:hover .beforefocu {
            opacity: 0;
        }

        .left:hover .afterfocu {
            opacity: 1;
        }

        .right:hover .beforefocu {
            opacity: 0;
        }

        .right:hover .afterfocu {
            opacity: 1;
        }

        .right {
            right: 0;
        }

        .chartbtn {
            position: absolute;
            top: 16.0625vw;
            width: 3.6458vw;
            height: 3.4375vw;
            z-index: 10;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .img2 {
            transform: scaleX(-1);
        }

        .afterfocu {
            margin-left: -3.6458vw;
            opacity: 0;
        }
    }
}

//
.business-scope {
    .bigtitle {
        // width: 6.875vw;
        height: 2.8646vw;
        font-size: 1.6667vw;
        font-family: MicrosoftYaHei;
        line-height: 1.3542vw;
        color: #3d3d3d;
        opacity: 1;
        text-align: center;
        margin-top: 2.5vw;
    }

    .smalltitle {
        text-align: center;
        // width: 21.5625vw;
        height: 1.3542vw;
        font-size: 1.0417vw;
        font-family: MicrosoftYaHei;
        line-height: 1.3542vw;
        color: #d8d8d8;
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.35);
        opacity: 1;
    }

    .otl {
        margin-top: 0.4688vw;
        margin-left: 49.1667vw;
        width: 1.7708vw;
        height: 0.1042vw;
        background: rgba(118, 118, 118, 0.39);
        opacity: 1;
    }

    .bus {
        margin-top: 3.4896vw;
        width: 100vw;
        height: 49.1667vw;
        background: rgba(77, 26, 26, 0.08);
        opacity: 1;
        position: relative;

        .bc {
            width: 100vw;
            height: 70%;
            background-image: url("@/assets/image/home_slices/3.png");
            position: absolute;
            top: 15%;
            opacity: 0.3;
            z-index: 0;
        }

        .one {
            position: relative;
            z-index: 1;
            width: 62.9688vw;
            height: 18.0208vw;
            margin: 0 auto;
            display: flex;
            align-items: center;

            .one_box {
                width: 53.25vw;
                height: 18.0208vw;
                // overflow: hidden;

                .box_img {
                    width: 31.25vw;
                    height: 18.0208vw;
                    transition: all 0.5s ease;

                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }

            .left {
                margin-left: 2.8125vw;

                .line1 {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.5625vw;

                    .dot {
                        width: 0.5208vw;
                        height: 1.0417vw;
                        background: #193780;
                    }

                    .title {
                        font-size: 1.0417vw;
                        color: #3d3d3d;
                        margin-left: 0.7292vw;
                    }
                }

                .line2 {
                    font-size: 0.7292vw;
                    color: #767676;
                    line-height: 1vw;
                }

                .line3 {
                    display: flex;
                    margin-top: 0.8333vw;
                    align-items: center;

                    .circle {
                        // border:1px solid #333;
                        width: 1.2vw;
                        height: 1.2vw;
                        font-size:1.2vw;
                        border-radius: 50%;
                        line-height: 1.2vw;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    span {
                        font-size: 0.7292vw;
                        margin-left: 0.225vw;
                        cursor: pointer;
                    }
                }
            }
        }

        .two {
            position: relative;
            z-index: 1;
            width: 62.9688vw;
            height: 29.0208vw;
            margin: 0 auto;
            display: flex;
            align-items: center;

            .two_box {
                // width: 100vw;
                height: 20.5833vw;
                // overflow: hidden;

                img {
                    height: 20.5833vw;
                    transition: all 0.5s ease;

                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }

            .right {
                margin-right: 2.8125vw;

                .line1 {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.5625vw;

                    .dot {
                        width: 0.5208vw;
                        height: 1.0417vw;
                        background: #193780;
                    }

                    .title {
                        font-size: 1.0417vw;
                        color: #3d3d3d;
                        margin-left: 0.7292vw;
                    }
                }

                .line2 {
                    font-size: 0.7292vw;
                    color: #767676;
                    line-height: 1vw;
                }

                .line3 {
                    display: flex;
                    margin-top: 0.8333vw;
                    align-items: center;

                    .circle {
                        // border:1px solid #333;
                        width: 1.2vw;
                        height: 1.2vw;
                        font-size:1.2vw;
                        border-radius: 50%;
                        line-height: 1.2vw;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    span {
                        font-size: 0.7292vw;
                        margin-left: 0.225vw;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.news {
    .bigtitle {
        // width: 6.875vw;
        height: 2.8646vw;
        font-size: 1.6667vw;
        font-family: MicrosoftYaHei;
        line-height: 1.3542vw;
        color: #3d3d3d;
        opacity: 1;
        text-align: center;
        margin-top: 2.5vw;
    }

    .smalltitle {
        text-align: center;
        // width: 21.5625vw;
        height: 1.3542vw;
        font-size: 1.0417vw;
        font-family: MicrosoftYaHei;
        line-height: 1.3542vw;
        color: #d8d8d8;
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.35);
        opacity: 1;
    }

    .otl {
        margin-top: 0.4688vw;
        margin-left: 49.1667vw;
        width: 1.7708vw;
        height: 0.1042vw;
        background: rgba(118, 118, 118, 0.39);
        opacity: 1;
    }

    .news-main {
        margin-top: 0.625vw;
        width: 100vw;
        height: 34.5833vw;
        background-image: url("@/assets/image/home_slices/1.jpg");
        opacity: 1;
        padding-top: 4.7396vw;

        .news_con {
            width: 60vw;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .item {
                width: 18.8542vw;
                color: #fff;

                .imgBc {
                    width: 18.8542vw;
                    height: 13.9063vw;
                    overflow: hidden;

                    img {
                        width: 18.8542vw;
                        height: 13.9063vw;
                        transition: all 0.5s ease;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                .title {
                    width: 100%;
                    font-size: 0.8333vw;
                    padding: 1.3542vw 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    border-bottom: 0.0521vw solid #ffffff;
                    margin-bottom: 1.3542vw;
                }

                .desc {
                    min-height: 2em;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    font-size: 0.625vw;
                }

                .btn {
                    background-image: url("@/assets/image/home_slices/2.png");
                    background-size: 100% 100%;
                    width: 1.5625vw;
                    height: 1.3542vw;
                    margin-top: 0.5208vw;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
