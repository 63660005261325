import request from '@/api/request'

// 企业动态
export function get_web_news_info_list(data) {
    return request({
      url: '/api/get_web_news_info_list',
      method: 'GET',
      params:data
    })
  }
  //详情页面
  export function get_web_news_info(data) {
    return request({
      url: '/api/get_web_news_info',
      method: 'GET',
      params:data
    })
  }