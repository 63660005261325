
import request from '@/api/request'

// 网站基本信息列表
export function get_web_base_info_list(data) {
    return request({
      url: '/api/get_web_base_info_list',
      method: 'GET',
      params:data
    })
  }