var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"top"},[_c('video',{staticClass:"vid",attrs:{"src":_vm.url,"loop":"","muted":"","autoplay":""},domProps:{"muted":true}})]),_c('div',{staticClass:"banner"},[_c('div',{staticClass:"font1"},[_vm._v("企业简介")]),_c('div',{staticClass:"font2"},[_vm._v("ENTERSPIRS INTRONUCTION")]),_c('div',{staticClass:"font3"}),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"title"},[_vm._v("企业概况")]),_c('div',{staticClass:"context",domProps:{"innerHTML":_vm._s(_vm.introduction)}}),_c('button',{staticClass:"banner-btn",on:{"click":function($event){return _vm.goPage('/about')}}},[_vm._v(" 了解更多 ")])]),_c('div',{staticClass:"develop"},[_c('p',{staticClass:"bigtitle"},[_vm._v("创新研发")]),_c('p',{staticClass:"smalltitle"},[_vm._v("INNOVATIVE RESEARCH & DEVELOPMENT")]),_c('div',{staticClass:"otl"}),_c('div',{staticClass:"lchart"},[_c('div',{staticClass:"swiper-container lchart-ul"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.loginImage),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide",style:({
                        backgroundImage: 'url(' + item.back + ')',
                    })},[_c('div',{staticClass:"item-context"},[_c('img',{attrs:{"src":require("@/assets/image/home_slices/icon" + item.icon + ".png")}}),_c('p',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"protext"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"more"},[_vm._v("了解更多+")])])])}),0),_vm._m(3),_vm._m(4)])])]),_c('div',{staticClass:"business-scope"},[_c('p',{staticClass:"bigtitle"},[_vm._v("业务范围")]),_c('p',{staticClass:"smalltitle"},[_vm._v("BUSINESS SCOPE")]),_c('div',{staticClass:"otl"}),_c('div',{staticClass:"bus"},[_c('div',{staticClass:"bc"}),_c('div',{staticClass:"one"},[_vm._m(5),_c('div',{staticClass:"left"},[_vm._m(6),_c('div',{staticClass:"line2"},[_vm._v(" 公司为医疗研发企业提供质量可靠、系统规范的临床监查服务，保障临床试验过程、数据和文件的台规性、科学性、真实性和完整性，为产品的注册申报提供 ")]),_c('div',{staticClass:"line3"},[_vm._m(7),_c('span',{on:{"click":function($event){return _vm.learn(0)}}},[_vm._v("了解更多详情")])])])]),_c('div',{staticClass:"two"},[_c('div',{staticClass:"right"},[_vm._m(8),_vm._m(9),_c('div',{staticClass:"line3"},[_vm._m(10),_c('span',{on:{"click":function($event){return _vm.learn(1)}}},[_vm._v("了解更多详情")])])]),_vm._m(11)])])]),_c('div',{staticClass:"news"},[_c('p',{staticClass:"bigtitle"},[_vm._v("新闻中心")]),_c('p',{staticClass:"smalltitle"},[_vm._v("NEWS CENTER")]),_c('div',{staticClass:"otl"}),_c('div',{staticClass:"news-main"},[_c('div',{staticClass:"news_con"},_vm._l((_vm.news),function(i){return _c('div',{key:i.news_id,staticClass:"item"},[_c('div',{staticClass:"imgBc"},[_c('img',{attrs:{"src":i.list_image_url,"alt":""}})]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(i.title_name))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(i.news_summary))]),_c('div',{staticClass:"btn",on:{"click":function($event){return _vm.jump(i.news_id)}}})])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font4 datav"},[_c('p',{staticClass:"number"},[_vm._v("200"),_c('span',[_vm._v("+")])]),_c('div',{staticClass:"txt"},[_vm._v("项目经验：200+")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font5 datav"},[_c('p',{staticClass:"number"},[_vm._v("300"),_c('span',[_vm._v("+")])]),_c('div',{staticClass:"txt"},[_vm._v("合作医院：300+")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font6 datav"},[_c('p',{staticClass:"number"},[_vm._v("500000"),_c('span',[_vm._v("+")])]),_c('div',{staticClass:"txt"},[_vm._v("协同医师网络500000+")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left chartbtn swiper-button-prev"},[_c('img',{staticClass:"beforefocu",attrs:{"src":require("@/assets/image/home_slices/zu 134.png")}}),_c('img',{staticClass:"img2 afterfocu",attrs:{"tra":"","src":require("@/assets/image/home_slices/zu 133.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right chartbtn swiper-button-next"},[_c('img',{staticClass:"img2 beforefocu",attrs:{"src":require("@/assets/image/home_slices/zu 134.png")}}),_c('img',{staticClass:"afterfocu",attrs:{"src":require("@/assets/image/home_slices/zu 133.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"one_box"},[_c('img',{staticClass:"box_img",attrs:{"src":require("@/assets/image/home_slices/4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line1"},[_c('div',{staticClass:"dot"}),_c('div',{staticClass:"title"},[_vm._v("医疗器械创新研发服务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle"},[_c('i',{staticClass:"el-icon-caret-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line1"},[_c('div',{staticClass:"dot"}),_c('div',{staticClass:"title"},[_vm._v("数字医疗与慢病管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line2"},[_c('p',[_vm._v("诚鼎医疗是零壹研究院引进和孵化企业,已经搭建了完善的慢病患者“共同照护”系统、流程和团队,经九安医疗投资支持,目前已经成功在全国200+公立医院及50+社区医院开展照护门诊,获得国内知名专家团队的支持和认可,管理全国近30万慢病患者。")]),_c('p',[_vm._v("共同照护是以患者为中心, 搭建医生、护士、营养师、运动师、照护师等多角色的照护团队,并借助AI智能、互联网、物联网等技术, 实现对慢病患者院内院外一体化、线下线上一体化和软硬件服务一体化的全病程规范化管理闭环，实现以患者为中心的服务供给。共同照护模式已经被写入《中国2型糖尿病防治指南(2020年版)》,被称为高度有效的患者管理模式,可以大大提升医院慢病患者管理的效率和质量,为慢病患者学习疾病知识、有效管理自身疾病提供平台指导,满足患者对医疗资源的体验和需求。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle"},[_c('i',{staticClass:"el-icon-caret-right"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"two_box"},[_c('img',{attrs:{"src":require("@/assets/image/home_slices/5.png"),"alt":""}})])
}]

export { render, staticRenderFns }