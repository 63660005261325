import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index/index.vue'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/about',
    name: 'about',
    component: ()=>import('@/views/aboutUs')
  },
  {
    path: '/innovative',
    name: 'innovative',
    component: ()=>import('@/views/Innovative')
  },
  {
    path: '/businessDirection',
    name: 'businessDirection',
    component: ()=>import('@/views/businessDirection')
  }
  ,
  {
    path: '/scientificResearchResults',
    name: 'scientificResearchResults',
    component: ()=>import('@/views/scientificResearchResults')
  },
  {
    path: '/news',
    name: 'news',
    component: ()=>import('@/views/news')
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: ()=>import('@/views/contactus')
  },
  {
    path: '/recruitment',
    name: 'recruitment',
    component: ()=>import('@/views/recruitment'),
   
  },
  {
    path:'/recruitmentDetail',
    name:'recruitmentDetail',
    component:()=>import('@/views/recruitment/recruitmentDetail')    
  },
  {
    path: '/detail',
    name: 'detail',
    component: ()=>import('@/views/detail')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }; // 将滚动位置重置为顶部
  }
}
)

export default router
