<template>
    <div class="box">
        <img class="logo" src="@/assets/image/2_1.png" alt="" />
        <div :class="['r', isShow ? 'r_act' : '']" @click="showMenu">
            <div class="a">
                <div class="one"></div>
                <div class="two"></div>
            </div>
        </div>
        <div class="menu" v-if="isShow">
            <div class="menu-list" @click="go('')" @mouseenter="changeback(40)" @mouseleave="changeback(20)">
                <div class="backg"></div>
                <div class="menu-num">01</div>
                <div class="menu-font">首页</div>
            </div>
            <div class="menu-list" @click="go('about')" @mouseenter="changeback(60)" @mouseleave="changeback(20)">
                <div class="backg"></div>
                <div class="menu-num">02</div>
                <div class="menu-font">关于我们</div>
            </div>
            <div class="menu-list" @click="go('innovative')" @mouseenter="changeback(80)" @mouseleave="changeback(20)">
                <div class="backg"></div>
                <div class="menu-num">03</div>
                <div class="menu-font">创新研发</div>
            </div>
            <div class="menu-list" @click="go('businessDirection')" @mouseenter="changeback(100)" @mouseleave="changeback(20)">
                <div class="backg"></div>
                <div class="menu-num">04</div>
                <div class="menu-font">业务方向</div>
            </div>
            <div class="menu-list" @click="go('scientificResearchResults')" @mouseenter="changeback(120)" @mouseleave="changeback(20)">
                <div class="backg"></div>
                <div class="menu-num">05</div>
                <div class="menu-font">研究成果</div>
            </div>
            <div class="menu-list" @click="go('news')" @mouseenter="changeback(140)" @mouseleave="changeback(20)">
                <div class="backg"></div>
                <div class="menu-num">06</div>
                <div class="menu-font">新闻资讯</div>
            </div>
            <div class="menu-list" @click="go('recruitment')" @mouseenter="changeback(160)" @mouseleave="changeback(20)">
                <div class="backg"></div>
                <div class="menu-num">07</div>
                <div class="menu-font">人力资源</div>
            </div>
            <div class="menu-list" @click="go('contactus')" @mouseenter="changeback(170)" @mouseleave="changeback(20)">
                <div class="backg"></div>
                <div class="menu-num">08</div>
                <div class="menu-font">联系我们</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isShow: false,
        };
    },
    methods: {
        changeback(val){
            console.log(val)
         var menu=document.querySelector('.menu')
         menu.style.background='linear-gradient('+val+'deg, #3156a4 40%, #0383cc 60%)'
       
        },
       
        go(value) {
            console.log(value);
            var address = "/" + value;
            console.log(address);
            this.$router.push(address);
            this.isShow = false;
        },

        showMenu() {
            this.isShow = !this.isShow;
            let dom = document.getElementById("app");
            let scrollTop = Math.abs(parseFloat(dom.style.top));
            dom.style.position = "";
            dom.style.top = "";
            dom.style.width = "100%";
            if (document.body) {
                document.body.scrollTop = scrollTop;
            }
            if (document.documentElement) {
                document.documentElement.scrollTop = scrollTop;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.box {
    width: 100%;
    position: absolute;
    z-index: 9;
    top: 1.0417vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10.4167vw;
    z-index: 999;
    .menu {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: linear-gradient(20deg, #3156a4 40%, #0383cc 60%);
        transition:all 1s;
        .backg {
            background: #ffffff;
            height: 100%;
            width: 0;
            transition: width 500ms;
            position: absolute;
            left: 0;
        }
        
        .menu-list:hover {
            color: #000;

            .backg {
                width: 100%; // z-index: 10;
            }
        }

        .menu-list {
            cursor: pointer;
            position: relative;
            float: left;
            width: 12.5vw;
            height: 100vh;

            border: 1px solid #f9f0f0df;
            color: rgba($color: #ffffff, $alpha: 0.5);
            display: flex;
            justify-content: center;
            //   align-items: center;
            .menu-num {
                width: 1.25vw;
                height: 1.4583vw;
                position: absolute;
                top: 21.7188vw;
                font-size: 1.0417vw;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 1.4583vw;
            }
            .menu-font {
                position: absolute;
                top: 24.0104vw;

                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 1.6667vw;
                line-height: 2.1875vw;
            }
        }
    }

    .logo {
        width: 12.0833vw;
        height: 4.4792vw;
        position: relative;
        z-index: 9;
    }
    .r_act {
        width: 1.9792vw;
        height: 1.9792vw;
        border: 0.2083vw solid #ffffff;
        position: fixed;
        left: 88vw;
        top: 1.1979vw;
        z-index: 10;
        cursor: pointer;
        &:hover> .a {
            transform: translate(-50%, -50%) rotate(45deg)!important;
        }
        .a {
            transform: translate(-50%, -50%) rotate(135deg)!important;
        }
    }
    .r {
        width: 1.9792vw;
        height: 1.9792vw;
        border: 0.2083vw solid #ffffff;
        position: fixed;
        left: 88vw;
        top: 1.1979vw;
        z-index: 10;
        cursor: pointer;
        &:hover {
            border: 0.2083vw solid #344795;
        }
        &:hover > .a {
            // background: #ffffff;
            transform: translate(-50%, -50%) rotate(180deg);
            transform-origin: center center;
        }
        &:hover .a > .one {
            background: #ffffff;
        }
        &:hover .a > .two {
            background: #ffffff;
        }
        .a {
            width: 1.0938vw;
            height: 1.0938vw;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.5s ease;
            .one {
                width: 1.0938vw;
                height: 0.2083vw;
                background: #344795;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .two {
                height: 1.0938vw;
                width: 0.2083vw;
                background: #344795;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>
